import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LayoutService } from './../../../core/services/layout.service';
import { PageTitleService } from './page-title.service';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';

interface MenuItem {
  url: string;
  label: string;
  title?: string;
}

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit, OnDestroy {
  public menuItems: MenuItem[] = [];
  public rootBreadcrumpDescription: string = 'Início';
  private _subscriptionRouter: Subscription;
  private _subscriptionCustomRouter: Subscription;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private pageTitleService: PageTitleService,
              private breadCrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.menuItems = this.createBreadcrumbs(this.activatedRoute.root);
    this._subscriptionRouter =  this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(
        () =>
          (this.menuItems = this.createBreadcrumbs(this.activatedRoute.root))
    );
    this._subscriptionCustomRouter = LayoutService.changeBreadCrumb$.subscribe((customRoot: ActivatedRoute) => {
      this.menuItems = this.createBreadcrumbs(customRoot);
    });

    // Se inscreve para ouvir as mudanças e alteravar o valor
    this.breadCrumbService.getRootBreadcrumbDescription()
    .subscribe( description => {
      this.rootBreadcrumpDescription = description;
    });
  }


  ngOnDestroy(): void {
    this._subscriptionRouter.unsubscribe();
    this._subscriptionCustomRouter.unsubscribe();
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: MenuItem[] = []
  ): MenuItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }
    for (const child of children) {

      const label = child.snapshot.data['breadcrumb'];
      const title = child.snapshot.data['title'] || label;
      const routeURL: string = child.snapshot.url
        .map((segment) => segment.path)
        .join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }
      if (label) {
        breadcrumbs.push({ label, url, title });
      }
      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  get pageTitle(): string {
    const lastUrl = this.menuItems[this.menuItems.length - 1];

    if (!this.pageTitleService?.showTitle) { return ''; }

    return lastUrl?.title || lastUrl?.label || 'Início';
  }
}
