import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { Util } from './util';

export class ValidatorsCustom {

  static noWhitespaceValidator(control: AbstractControl) {
    let field = control.value;
    if (typeof field === 'number') { return null; }
    if (!field) { return { required: true }; }
    if (typeof field === 'object') { return null; }
    field = field.toString()?.trim();
    if (!field) { return { required: true }; }
    return null;
  }

  /**
   * Verifica se o CPF é valido
   * @param control Campo a ser validado
   */
  static validCpf(control: AbstractControl) {

    let cpf = control.value;
    if (!cpf) { return null; }
    cpf = cpf.toString().trim();
    if (!cpf) { return null; }

    let soma = 0;
    let resto;

    if (
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999') {
      return { cpfInvalid: true };
    }

    for (let i = 1; i <= 9; i++) {
      soma = soma + Number(cpf.substring(i - 1, i)) * (11 - i);
    }

    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) {
      resto = 0;
    }

    if (resto !== Number(cpf.substring(9, 10))) {
      return { cpfInvalid: true };
    }

    soma = 0;

    for (let i = 1; i <= 10; i++) {
      soma = soma + Number(cpf.substring(i - 1, i)) * (12 - i);
    }
    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) {
      resto = 0;
    }

    if (resto !== Number(cpf.substring(10, 11))) {
      return { cpfInvalid: true };
    }

    return null;
  }

  /**
   * Verifica se o CNPJ é valido
   * @param control Campo a ser validado
   */
  static validCnpj(control: AbstractControl) {
    const cnpj = control.value.trim();

    if (!cnpj) { return null; }

    if (cnpj.length !== 14) {
      return { cnpjInvalid: true };
    }

    if (cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999') {
      return { cnpjInvalid: true };
    }

    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += Number(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (resultado !== Number(digitos.charAt(0))) {
      return { cnpjInvalid: true };
    }

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += Number(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== Number(digitos.charAt(1))) {
      return { cnpjInvalid: true };
    }

    return null;
  }

  /**
   * Verifica se o CPF ou CNPJ é valido
   * @param control Campo a ser validado
   */
  static validCpfOrCnpj(control: AbstractControl) {

    let value = control.value;
    if (!value) { return null; }

    value = value.toString().trim();
    if (!value) { return null; }

    value = Util.removeCpfCnpjMask(value);

    if (value.length <= 11) {
      return ValidatorsCustom.validCpf(control);
    }

    if (value.length > 11) {
      return ValidatorsCustom.validCnpj(control);
    }

    return null;
  }

  /**
   * Valida se o campo tem o númeto maximo informado
   * @param length Tamanho a ser utilizado na validação
   */
  static maxLength(length: number): ValidatorFn {

    return (control: AbstractControl): { [ key: string ]: boolean } | null => {
      const valueControl: string = control.value ? control.value.toString().trim() : '';

      if (valueControl && valueControl.length > length) { return { maxLength: true }; }

      return null;
    };
  }

  /**
   * Valida se o campo tem o númeto minimo informado
   * @param length Tamanho a ser utilizado na validação
   */
  static minLength(length: number): ValidatorFn {
    return (control: AbstractControl): { [ key: string ]: boolean } | null => {
      if (control.value && !isNaN(control.value)) {
        const valueControl = control.value ? control.value.toString().trim() : '';
        if (valueControl && valueControl.length < length) {
          return { minLength: true };
        }
      }
      return null;
    };
  }

  static minNumber(min: number): ValidatorFn {
    return (control: FormControl): { [ key: string ]: any } => {
      const val: number = control.value;

      if (!val && (isNaN(val) || val < min)) {
        return { 'minNumber': true };
      }
      return null;

    };
  }

  /**
   * Verifica se o e-mail é valido
   * @param control Campo a ser validado
   */
  static validEmail(control: AbstractControl) {
    if (control.value) {
      const email = control.value.trim();
      // tslint:disable-next-line: max-line-length
      const regexP = /[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/g;

      if (email && !regexP.test(email)) {
        return { emailInvalid: true };
      }
    }
    return null;
  }

  /**
   * Verifica se o e-mail é valido
   * @param control Campo a ser validado
   */
  static validatePasswordValid(control: AbstractControl) {
    const password = control.value.trim();
    const regexP = /^(?=\D*\d)(?=[^a-zA-Z]*[a-zA-Z]).{6,30}$/g;

    if (password && !regexP.test(password)) {
      return { passwordInvalid: true };
    }
    return null;
  }

  /**
   * Verifica se o array está vazio
   * @param control Campo a ser validado
   */
  static arrayLength(control: AbstractControl) {
    const array: any[] = control.value;
    if (array && array.length <= 0) { return { arrayEmpty: true }; }

    return null;
  }

  /**
   * Valida se a senha é igual da de confirmar senha
   * @param form Campo a ser validado
   */
  static validatePassword(form: FormGroup) {
    const novaSenha = form.get('novaSenha').value;
    const novaSenhaRepeat = form.get('novaSenhaRepeat').value;

    if (!novaSenhaRepeat || !novaSenha) {
      return null;
    }

    if (novaSenha.trim() !== novaSenhaRepeat.trim()) {
      form.get('novaSenha').setErrors({ passwordNotMatch: true });
      form.get('novaSenhaRepeat').setErrors({ passwordNotMatch: true });
      return null;
    }

    form.get('novaSenha').setErrors({ passwordNotMatch: null });
    form.get('novaSenhaRepeat').setErrors({ passwordNotMatch: null });
    form.get('novaSenha').updateValueAndValidity({ onlySelf: true });
    form.get('novaSenhaRepeat').updateValueAndValidity({ onlySelf: true });

    return null;
  }

  /**
   * Verifica se a data é inválida
   * @param control Campo a ser validado
   */
  static validDate(control: AbstractControl) {
    if (control.value) {
      if (!moment(control.value).isValid()) {
        return { validDate: true };
      }
    }

    return null;
  }

  /**
   * Verifica se é a data minina
   * @param control Campo a ser validado
   */
  static minDate(control: AbstractControl) {
    if (control.value) {
      const date = new Date(control.value);
      if (moment(date).isValid() && date.getFullYear() < (new Date(0).getFullYear() - 69)) {
        return { minDate: true };
      }
    }

    return null;
  }

  /**
   * Verifica se a data é superior a data atual
   * @param control Campo a ser validado
   */
  static higherDate(control: AbstractControl) {
    const currentDate = new Date();
    const date = new Date(control.value);

    if (moment(date).isValid() && currentDate < date) {
      return { higherDate: true };
    }

    return null;
  }

  /**
   * Valida o número do telefone/celular
   * @param control Campo a ser validado
   */
  static validPhone(control: AbstractControl) {
    const phone: string = control.value?.trim();
    if (phone && (phone.length < 10 || phone.length > 11)) {
      return { phoneInvalid: true };
    }

    return null;
  }

  /**
   * Validação para ver se a data final é maior que a data inicial
   * @param initialDateName Data Inicial
   * @param finalDateName Data Final
   */
  static dateBiggerCurrent(initialDateName: string, finalDateName: string) {
    return (group: FormGroup): { [ key: string ]: any } => {
      const initalDate = group.controls[ initialDateName ];
      const finalDate = group.controls[ finalDateName ];
      if (initalDate.value && finalDate.value) {
        if (initalDate.value && finalDate.value && (initalDate.value > finalDate.value)) {
          initalDate.setErrors({ dateBiggerCurrent: true });
          finalDate.setErrors({ dateBiggerCurrent: true });
          return { dateBiggerCurrent: true };
        } else {
          if (initalDate.hasError('dateBiggerCurrent')) {
            delete initalDate.errors[ 'dateBiggerCurrent' ];
            initalDate.updateValueAndValidity();
          }
          if (finalDate.hasError('dateBiggerCurrent')) {
            delete finalDate.errors[ 'dateBiggerCurrent' ];
            finalDate.updateValueAndValidity();
          }
        }
      }

      return null;
    };
  }

  /**
   * Validação para ver se a data final é maior que a data inicial
   * @param initialDateName Data Inicial
   * @param finalDateName Data Final
   */
  static sevenDayBreak(initialDateName: string, finalDateName: string) {
    return (group: FormGroup): { [ key: string ]: any } => {
      const initalDate = group.controls[ initialDateName ];
      const finalDate = group.controls[ finalDateName ];

      if (initalDate.value && finalDate.value) {
        const initalDateMoment = moment(initalDate.value);
        const finalDateMoment = moment(finalDate.value);
        if (initalDate.value && finalDate.value && (initalDate.value <= finalDate.value) && (finalDateMoment.diff(initalDateMoment, 'days') > 7)) {
          initalDate.setErrors({ sevenDayBreak: true });
          finalDate.setErrors({ sevenDayBreak: true });
          return { sevenDayBreak: true };
        } else {
          initalDate.setErrors(null);
          finalDate.setErrors(null);
        }
      }

      return null;
    };
  }

  static dateBiggerCurrentAndsevenDayBreak(initialDateName: string, finalDateName: string) {
    return (group: FormGroup): { [ key: string ]: any } => {
      const initalDate = group.controls[ initialDateName ];
      const finalDate = group.controls[ finalDateName ];
      if (initalDate.value && finalDate.value && this.higherDate(initalDate) == null && this.higherDate(finalDate) == null) {

        if (initalDate.value && finalDate.value && (initalDate.value > finalDate.value)) {
          initalDate.setErrors({ dateBiggerCurrent: true });
          finalDate.setErrors({ dateBiggerCurrent: true });
          return { dateBiggerCurrent: true };
        } else {
          const initalDateMoment = moment(initalDate.value);
          const finalDateMoment = moment(finalDate.value);
          if (initalDate.value && finalDate.value && (initalDate.value <= finalDate.value) && (finalDateMoment.diff(initalDateMoment, 'days') > 7)) {
            initalDate.setErrors({ sevenDayBreak: true });
            finalDate.setErrors({ sevenDayBreak: true });
            return { sevenDayBreak: true };
          } else {
            initalDate.setErrors(null);
            finalDate.setErrors(null);
          }
        }
      }

      return null;
    };
  }

  /**
   * Verifica se o ano é valido
   * @param control Campo a ser validado
   */
  static validYear(control: AbstractControl) {
    const year = control.value;

    if (year) {
      if (year.toString().length !== 4 || year < 1950 || year > 2100) {
        return { validYear: true };
      }
    }

    return null;
  }

  /**
   * Verifica se a quantidade de vagas na turma atende ao critério da etapa e modalidade
   */
  static validVacancies(form: FormGroup) {
    const etapa = form.get('etapa').value;
    const temporalidade = form.get('temporalidade').value;
    const vagas = form.get('vagas').value;

    if (etapa === 'INFANTIL' || etapa === 'MULTIETAPA') {
      if (vagas <= 35) {
        return { validVacancies: true };
      }
      return { validVacancies: false };
    }

    if (etapa === 'ANOS_INICIAIS' || etapa === 'ANOS_FINAIS') {
      switch (temporalidade) {
        case '1º Ano' || '2º Ano':
          if (vagas <= 35) {
            return { validVacancies: true };
          }
          return { validVacancies: false };
        case '3º Ano' || '4º Ano':
          if (vagas <= 40) {
            return { validVacancies: true };
          }
          return { validVacancies: false };
        case '5º Ano' || '6º Ano' || '7º Ano' || '8º Ano' || '9º Ano':
          if (vagas <= 50) {
            return { validVacancies: true };
          }
          return { validVacancies: false };
      }
    }

    if (etapa === 'ENSINO_MEDIO') {
      if (vagas <= 50) {
        return { validVacancies: true };
      }
      return { validVacancies: false };
    }

  }

  static minValueFieldPropostaVagas(form: FormGroup) {
    const vagas = form.get('vagas').value;
    const propostaVagas = form.get('propostaVagas').value;
    if (propostaVagas < vagas) {
      form.get('propostaVagas').setErrors({ minValueFieldPropostaVagas: true });
    }
    return null;
  }

  // /**
  //  * Valida se o campo tem 2 ou mais espaços em branco.
  //  * @returns Retorna a propriedade 'nomeCompostoInvalid': true na validação do formcontrol caso o texto não seja seguido por um espaço único e outra palavra, repetidamente
  //  */
  // static nomeComposto(control: AbstractControl){
  //   const value = control.value;
  //   // Verifica se há uma palavra seguida por um espaço único e outra palavra, repetidamente
  //   const regex = /^(\w+\s)+\w+$/;
  //   if (value && regex.test(value)) {
  //     return null;
  //   }
  //   return { 'nomeCompostoInvalid': true };
  // };

  static validName(control: AbstractControl){
    const value = control.value;

    // Verifica se existem 4 caracteres iguais ou mais
    if (value && /(.)\1{3,}/.test(value)) {
      return { 'invalidName': true };
    }

    // Verifica se existem 2 ou mais espaços consecutivos
    if (value && /\s{2,}/.test(value)) {
      return { 'invalidName': true };
    }

    // Verifica se há uma palavra seguida por um espaço único e outra palavra, repetidamente
    let regex = /^(\w+\s)+\w+$/;
    if (value && !regex.test(value)) {
      return { 'invalidName': true };
    }

    // Verifica se tem espaço em branco no início ou no final da palavra
    if (value && (/^\s|\s$/.test(value))) {
      return { 'invalidName': true };
    }

    // Verifica se no valor existem uma sequência apenas com 1 caractere
    regex = /\b\w{1}\b/g;
    if (value && regex.test(value)) {
      return { 'invalidName': true };
    }

    return null;
  };

  static validAnoConclusao(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value;
    if (!value) {
      return null;
    }
    const currentYear = moment().year();
    const year = parseInt(value, 10);
    if (!/^\d{4}$/.test(value) || year < 1940 || year > currentYear) {
      return { 'anoInvalido': true };
    }
    return null;
  }
}
