import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificacaoCreateDto } from '../dtos/notificacao/norificacao-create.dto';
import { NotificacaoListDto } from '../dtos/notificacao/norificacao-list.dto';
import { NotificacaoReadDto } from '../dtos/notificacao/norificacao-read.dto';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class NotificacaoService {

  private controller = 'Notificacao';

  constructor(public apiService: ApiService) { }

  getByFilter(params?: URLSearchParams) {
    const filters = params ? `?${params.toString()}` : '';
    return this.apiService.get(`${this.controller}${filters}`)
    .pipe(map(response => response.body.data.map(item => new NotificacaoListDto(item))));
  }

  read(obj: NotificacaoReadDto) {
    return this.apiService.patch(`${this.controller}`, obj);
  }

  readVisualizarTodas(id: string): Observable<any> {
    return this.apiService.patch(`${this.controller}/visualizar-todas/${id}`, null);
  }

  send(obj: NotificacaoCreateDto) {
    return this.apiService.post(`${this.controller}`, obj);
  }

}
