import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

  /**
   * Este service é utilizado para mudar exclusivamente o primeiro valor do breadcrumb que por default é Início
   * para utiliza-lo, basta fazer a injeção de dependência no construtor e chamar os seus respectivos métodos
   * é convenimente utilizar o setRootBreadcrumbDescription no onInit dos componentes e o método
   * etDefaultBreadcrumbDescription no onDestroy, para garantir que o breadcrumb volte ao valour padrão quando sair do componente.
   */
@Injectable({
    providedIn: 'root'
  })

  export class BreadcrumbService {
    private rootBreadcrumbDescription = new BehaviorSubject<string>('Início');

    /**
     * Esta função altera o valor do breadcrumb "Início" para o valor desejado.
     * @param description Novo valor para o breadcrumb.
     */
    public setRootBreadcrumbDescription(description: string) {
        this.rootBreadcrumbDescription.next(description);
    }

    /**
     * Método para recuperar o valor atual definido para o breadcrumb.
     * @returns retorna um observable do tipo Subject<string> com o valor.
     */
    public getRootBreadcrumbDescription() {
        return this.rootBreadcrumbDescription.asObservable();
    }

    /**
     * Método que redefine o primeiro breadcrumb para o valor default que é  "Início".
     */
    public setDefaultBreadcrumbDescription() {
        this.rootBreadcrumbDescription.next('Início');
    }
  }
