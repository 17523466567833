import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { Subscription } from 'rxjs';
import { NotificacaoListDto } from 'src/app/core/dtos/notificacao/norificacao-list.dto';
import { NotificacaoReadDto } from 'src/app/core/dtos/notificacao/norificacao-read.dto';
import { DadosPeriodoAprovacaoDto } from 'src/app/core/dtos/periodo-aprovacao/dados-periodo-aprovacao.dto';
import { AuthService } from 'src/app/core/services/auth.service';
import { NotificacaoService } from 'src/app/core/services/notificacao.service';
import { DownloadEvent, RelatorioService } from 'src/app/core/services/relatorio.service';
import { FormBase } from 'src/app/shared/utils/form-base';
import { Util } from 'src/app/shared/utils/util';
import { environment } from 'src/environments/environment';
import { MENUS_INFOBAR } from './navbar-infobar-menu.constant';
import { MENUS_NAVBAR, NavbarItem } from './navbar-menu.constant';
import { MIN_YEAR_BLOCK } from 'src/app/shared/utils/year-permission';

@Component({
  selector: 'app-navbar-auth',
  templateUrl: './navbar-auth.component.html',
  styleUrls: ['./navbar-auth.component.scss']
})
export class NavbarAuthComponent extends FormBase implements OnInit {

  downloadEvent: DownloadEvent;
  downloadEventSubscription: Subscription;
  usuarioSession: any;
  private hubConnection: HubConnection;
  notificacoes: NotificacaoListDto[] = [];
  allNotifications: NotificacaoListDto[] = [];

  constructor(
    private notificacaoService: NotificacaoService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private cdr: ChangeDetectorRef
    ) {
      super(router, activatedRoute);
  }

  public menus = MENUS_NAVBAR;
  public menusInfoBar = MENUS_INFOBAR;
  public menusTratados = new Array<NavbarItem>();

  ngOnInit(): void {
    this.setMenu();
    this.getUsuarioSession();
  }

  public getUsuarioSession(): void {
    this.usuarioSession = Util.getUsuarioSession();
  }

  subscribeDownloadEvent(): void{
    this.downloadEventSubscription = RelatorioService.downloadEvent$.subscribe((evento) => {
      this.downloadEvent = evento;
    });
  }

  createConnection() {
    this.hubConnection = new HubConnectionBuilder()
    .withUrl(environment.apiUrl + 'hub/notificacoes')
    .build();
  }

  registerOnServerEvents(): void {
    this.hubConnection.on('ReceiveNotification', (data: any) => {
      this.notificacoes.unshift(new NotificacaoListDto(data));
      this.addNotification();
    });

    this.hubConnection.on('PeriodoAprovacao', (data: DadosPeriodoAprovacaoDto) => {
      localStorage.setItem('seectpb.dadosPeriodoAprovacao', btoa(JSON.stringify(data)));
    });
  }

  startConnection(): void {
    this.hubConnection
      .start()
      .then(() => {
          this.connectToGroup(this.usuarioSession.id);
      })
      .catch(() => {
        setTimeout(function() { this.startConnection(); }, 5000);
      });
  }

  connectToGroup(userId: string) {
    this.hubConnection.invoke('ConnectToNotifications', userId);
  }

  addNotification() {
    const el = document.querySelector('.notification');
    const count = Number(el.getAttribute('data-count')) || 0;
    el.classList.remove('notify');
    setTimeout(() => {
      el.setAttribute('data-count', (count + 1).toString());
      el.classList.add('notify');
    }, 1000);

    if (count === 0){
        el.classList.add('show-count');
    }
  }

  getNotificationNotRead() {
    const params = Util.createFilterStatusActive();
    params.append('usuarioId', this.usuarioSession.id);
    params.append('visualizada',  'false');
    params.append('orderByDesc',  'true');
    this.notificacaoService.getByFilter(params).subscribe(
      (response) => {
        this.notificacoes = response;
        this.updateNotificationIcon();
      }
    );
  }

  getAllNotification() {
    const params = Util.createFilterStatusActive();
    params.append('usuarioId', this.usuarioSession.id);
    params.append('quantidadeMaxima', '10');
    params.append('orderByDesc',  'true');
    this.notificacaoService.getByFilter(params).subscribe(
      (response) => {
        this.allNotifications = response;
      }
    );
  }

  updateNotificationIcon() {
    if (this.notificacoes.length >= 0) {
      const el = document.querySelector('.notification');
      el.setAttribute('data-count', this.notificacoes.length.toString());
      const count = Number(el.getAttribute('data-count')) || 0;
      el.classList.remove('notify');
      el.classList.add('notify');
      if (count > 0){
        el.classList.add('show-count');
      } else {
        el.classList.remove('show-count');
      }
    }
  }

  readNotification(item: NotificacaoListDto) {
    this.router.navigate(['/ajuda/analise', item.entidadeRelacionada.id.toString()]).then(
      (result) => {
        if (result) {
          const entity = new NotificacaoReadDto();
          entity.notificacaoUsuarioId = item.id;
          entity.visualizada = true;
          this.notificacaoService.read(entity).subscribe(
            () => {
              this.getNotificationNotRead();
            }
          );
        }
      }
    );
  }

  readNotifications() {
    this.router.navigate(['/notificacoes']).then(
      (result) => {
        if (result) {
    const usuarioId = this.usuarioSession.id;
    this.notificacaoService.readVisualizarTodas(usuarioId).subscribe(
            () => {
              this.getNotificationNotRead();
            }
          );
        }
      }
    );
  }

  clearNotifications() {
    this.allNotifications = [];
  }


  shouldShowNavbarItem(item: NavbarItem): boolean {
    if (!item.profiles){
      return true;
    }

    return item.profiles.some(profile => this.usuarioSession.perfil?.toUpperCase().includes(profile.toUpperCase()));
  }

  get ambienteQA() {
    return window.location.href.includes('codatahml');
  }

  get anoLetivo(){
    return this.authService.getAnoLetivo().ano;
  }

  private setMenu() {
    if (Number(this.anoLetivo) >= MIN_YEAR_BLOCK) {
      const labels2024 = ['Processo de Matrículas', 'Meus Alunos', 'Painel de Monit.', 'Desempenho Final'];
      this.menusTratados = this.menus.filter( menu => !labels2024.includes(menu.label));
      return;
    }
    if (Util.isUserGRE() || Util.isUserAdmin()) {
      this.menus = this.menus.filter(menu => menu.label !== 'Minhas Turmas');
    }

    this.menusTratados = this.menus.filter(menu => menu.label !== 'Meus Estudantes');
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(['/auth/logout']);
  }
}
